<template>
  <v-container>
    <h1 class="py-16 text">404 Page not Found</h1>
    <img width="500" :src="img" alt="image not found 404" class="img mt-16" />
  </v-container>
</template>

<script>
import img from "@/assets/images/SVG/drawkit-grape-pack-illustration-7.svg";

export default {
  name: "PageNotFound",
  data: () => ({
    img,
  }),
};
</script>

<style lang="scss" scoped></style>
